import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { useForm, Controller, useFormContext, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { YupShape, DialogTitle, FormInput, FileUploader, FileUploadInput, FormButton, FormSelect } from "suregifts-component-lib";
import * as yup from "yup";
import { ProductConfigModel, useConfigureProduct, useGetCategories } from "../../../services/merchants";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { FormFileSelector } from "../../../components/molecules/FormFileSelector";
import { OnOffSelectControl } from "../../../components/molecules/OnOffSelectControl";

export const campaignSchema = yup
  .object<YupShape<ProductConfigModel>>({
    name: yup.string().required(),
    productId: yup.string().required(),
  })
  .required();
export const ConfigureProductModal = ({}) => {
  const { state } = useLocation();
  const formMethods = useForm<ProductConfigModel>({
    resolver: yupResolver(campaignSchema),
    values: { ...state?.data, categoryIds: state?.data.categories?.map((i) => i.id) ?? [] },
  });
  const { data: categories } = useGetCategories();
  const {
    control,
    formState: { errors, isValid },
    register,
    getValues,
    handleSubmit,
    watch,
  } = formMethods;
  const queryClient = useQueryClient();

  const { mutate: configureProduct, isLoading } = useConfigureProduct();

  const submitForm = (data) => {
    configureProduct(data, {
      onSuccess: () => {
        toast.success("Product configured successfully.");
        queryClient.invalidateQueries("products");
        handleClose();
      },
    });
  };

  const nagivate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("./");
  };
  const isSwapable = watch("isSwapable");

  return (
    <FormProvider {...formMethods}>
      <Dialog open maxWidth={"xs"} onClose={handleClose}>
        <DialogTitle title={`Configure ${getValues("name")}`} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={2}>
            <FormInput label="Name" {...register("name")} helperText={errors.name?.message} />
            <OnOffSelectControl label="Is Active" name="isActive" />
            <OnOffSelectControl label="Is Swapable" name="isSwapable" />
            {isSwapable && <FormInput label="Swap Rate" {...register("swapRate")} helperText={errors.swapRate?.message} />}
            <OnOffSelectControl label="Is Visible Online" name="isVisibleOnline" />
            <OnOffSelectControl label="Is Visible to Business API" name="isVisibleToApi" />
            <FormInput label="Order Discount Margin(%)" {...register("orderDiscountMargin")} helperText={errors.orderDiscountMargin?.message} />
            <FormInput label="Profit Margin(%)" {...register("profitMarginOverride")} helperText={errors.profitMarginOverride?.message} />
            <FormFileSelector name="imageUrl" label="Select Image" />
            <Controller
              control={control}
              name="categoryIds"
              render={({ field }) => <FormSelect multiple helperText={errors.categoryIds?.message} {...field} label={"Categories"} options={categories} />}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormButton label="Submit" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
