import { DashboardIcon, General3Icon, NavLinkProps, General4Icon, useAuthContext, PeopleNavIcon } from "suregifts-component-lib";
import { RolesEnum } from "../../../../services/auth.api";
import { useMerchantDashboardContext } from "../../../merchant-dashboard/MerchantDashboardContext";
import { MerchantPaymentType } from "../../../../services/merchant-dashboard/merchant";
import PasswordIcon from "@mui/icons-material/Password";

export const useGetMerchantDashboardLinks = () => {
  const { user } = useAuthContext();
  const { merchant } = useMerchantDashboardContext();

  const links: NavLinkProps[] = [
    {
      to: "/merchant-dashboard/voucher-operations",

      icon: DashboardIcon,
      title: "Dashboard",
    },
    {
      to: "/merchant-dashboard/redemptions",
      icon: General3Icon,
      title: "Redemptions",
    },
    {
      to: "/merchant-dashboard/activations",
      icon: General4Icon,
      title: "Activations",
    },
  ];
  if (merchant?.paymentTypeId === MerchantPaymentType.PrepaidStore) {
    links.push({
      to: "/merchant-dashboard/store-wallets",
      icon: General4Icon,
      title: "Wallet Transactions",
    });
  }
  if (merchant?.paymentTypeId === MerchantPaymentType.PrepaidVendor) {
    links.push({
      to: "/merchant-dashboard/wallet-transactions",
      icon: General4Icon,
      title: "Wallet Transactions",
    });
  }
  if (user?.role === RolesEnum.STORE_CONTROLLER) {
    links.push({
      to: "/merchant-dashboard/stores",
      icon: General4Icon,
      title: "Stores",
    });
    links.push({
      to: "/merchant-dashboard/personnels",
      icon: PeopleNavIcon,
      title: "Personnels",
    });
    links.push({
      to: "/merchant-dashboard/credentials",
      icon: PasswordIcon,
      title: "Credentials",
    });
  }
  return links;
};
