import { CouponBagNavIcon, DashboardIcon, General3Icon, NavLinkProps, RefundIcon } from "suregifts-component-lib";

export const accountLinks: NavLinkProps[] = [
  {
    to: "/account/dashboard",
    icon: DashboardIcon,
    title: "Dashboard",
  },
  {
    to: "/account/payments",
    icon: General3Icon,
    title: "Payments",
  },
  {
    to: "/account/refunds",
    icon: RefundIcon,
    title: "Refunds",
  },
  {
    to: "/account/vouchers",
    icon: CouponBagNavIcon,
    title: "Vouchers",
  },
  {
    to: "/account/redemptions",
    icon: General3Icon,
    title: "Redemptions",
  },
  {
    to: "/account/activations",
    icon: General3Icon,
    title: "Activations",
  },
  {
    to: "/account/merchants",
    icon: General3Icon,
    title: "Merchants",
  },
  {
    to: "/account/stores",
    icon: General3Icon,
    title: "Stores",
  },
  {
    to: "/account/restore-income",
    icon: General3Icon,
    title: "Exp. Voucher Income",
  },
  {
    to: "/account/order-summary-report",
    icon: General3Icon,
    title: "Order Report",
  },
];

export default accountLinks;
