import { RouteObject } from "react-router-dom";
import AbandonCartScreen from "../pages/customer-support/carts/list/AbandonCartScreen";
import DashboardScreen from "../pages/customer-support/dashboard/DashboardScreen";
import PaymentsScreen from "../pages/customer-support/payments/PaymentsScreen";
import UsersScreen from "../pages/customer-support/users/list/UsersScreen";
import AbandonedCartInfoScreen from "../pages/customer-support/carts/details/CartInfoScreen";
import OrdersScreen from "../pages/customer-support/orders/list/OrdersScreen";
import { OrderScreenContextProvider } from "../pages/customer-support/orders/details/context/ScreenContext";

import CancelOrderModal from "../pages/customer-support/orders/details/modals/CancelOrderModal";

import UserInfoScreen from "../pages/customer-support/users/details/UserInfoScreen";

import AssignEmailModal from "../pages/customer-support/dashboard/modals/AssignEmailModal";
import CheckBalanceModal from "../pages/merchant-backend/voucher-operations/modals/CheckBalanceModal";

export const customerSuppporRoutes: RouteObject[] = [
  { index: true, element: <DashboardScreen /> },
  {
    path: "dashboard",
    element: <DashboardScreen />,
    children: [
      {
        path: "voucher-check",
        element: <CheckBalanceModal />,
      },
      {
        path: "voucher-check/:voucherCode/assign-email",
        element: <AssignEmailModal />,
      },
    ],
  },
  { path: "orders", element: <OrdersScreen /> },
  { path: "orders/:orderId", element: <OrderScreenContextProvider />, children: [{ path: "cancel", element: <CancelOrderModal /> }] },
  { path: "carts", element: <AbandonCartScreen /> },
  { path: "carts/:cartId", element: <AbandonedCartInfoScreen /> },
  { path: "payments", element: <PaymentsScreen /> },
  { path: "users", element: <UsersScreen /> },
  {
    path: "users/:userId",
    element: <UserInfoScreen />,
    // children: [
    //   { path: "credit", element: <CreditUserModal /> },
    //   { path: "debit", element: <DebitUserModal /> },
    // ],
  },
];
