import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DialogTitle, FormInput, FormButton } from "suregifts-component-lib";
import { CredentialModel } from "../../../services/merchants";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { OnOffSelectControl } from "../../../components/molecules/OnOffSelectControl";
import { useUpdateCredential } from "../../../services/merchant-dashboard/merchant";

export const UpdateCredentialModal = ({}) => {
  const { state } = useLocation();
  const formMethods = useForm<CredentialModel>({
    values: {
      ...state.data,
      credentialId: state.data.id,
    },
  });

  const {
    formState: { isValid },
    register,
    handleSubmit,
  } = formMethods;
  const queryClient = useQueryClient();

  const { mutate: updateCredential, isLoading } = useUpdateCredential();

  const submitForm = (data) => {
    updateCredential(data, {
      onSuccess: () => {
        toast.success("API credential updated successfully");
        queryClient.invalidateQueries("credentials");
        handleClose();
      },
    });
  };

  const nagivate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("./");
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog open maxWidth={"xs"} onClose={handleClose}>
        <DialogTitle title={`Update API Credentials`} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={2}>
            <FormInput label="Allowed IP address(s). comma separated" {...register("allowedIps")} />
            <OnOffSelectControl label="Is Active" name="isActive" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormButton label="Submit" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
