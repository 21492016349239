import { Box, IconButton, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { SearchContainer, PageHeaderTitle, MainTable, TableBody, usePagination, formatNumber, Pagination, useDocumentTitle } from "suregifts-component-lib";
import { useExportWebOrderSummaryReport, useGetWebOrderSummaryReport } from "../../../services/vouchers";
import { fixedCellStyle } from "../../../helpers/cssHelper";

function WebOrderSummaryScreen() {
  const [filter, setFilter] = useState({ year: undefined, page: 1 });
  const { data: summaryData, isFetching } = useGetWebOrderSummaryReport();
  const { mutate: exportIncome, isLoading: exporting } = useExportWebOrderSummaryReport();
  const { data, total } = usePagination(filter.page, summaryData);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Order Report");
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={"Order Report"} />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        ></Box>
      </SearchContainer>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Month/Year</TableCell>
            <TableCell>Shipping Fee</TableCell>
            <TableCell>Printing Fee</TableCell>
            <TableCell>Service Fee</TableCell>
            <TableCell>Sub Total</TableCell>
            <TableCell>Amount Paid</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={`${item.year}-${item.month}`}>
              <TableCell style={fixedCellStyle}>{`${item.month}/${item.year}`}</TableCell>
              <TableCell>{formatNumber(item.shippingFee)}</TableCell>
              <TableCell>{formatNumber(item.printingFee)}</TableCell>
              <TableCell>{formatNumber(item.serviceFee)}</TableCell>
              <TableCell>{formatNumber(item.subTotal)}</TableCell>
              <TableCell>{formatNumber(item.amountPaid)}</TableCell>
              <TableCell>
                <IconButton onClick={() => exportIncome({ year: item.year, month: item.month })}>
                  <FileDownloadIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={total} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default WebOrderSummaryScreen;
