import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGenerateCredential, useGetCredentials, useUpdateCredential } from "../../../services/merchants";
import { TableHead, TableRow, TableCell, IconButton, Box, Modal, Typography, Dialog, DialogContent, Tooltip } from "@mui/material";
import { MainTable, TableBody, BooleanLabel, EditPencilIcon, PageHeaderTitle, SearchContainer, useDocumentTitle } from "suregifts-component-lib";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { UpdateCredentialModal } from "./UpdateCredentialModal";
function CredentialScreen() {
  const { merchantId, credentialId } = useParams();
  const { isLoading, data, refetch } = useGetCredentials(merchantId);
  const { isLoading: isGenerating, mutate: generateCredential } = useGenerateCredential(merchantId);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [secret, setSecret] = useState("");

  const pageTitle = `${data?.name} API Credentials`;
  useDocumentTitle(pageTitle);
  return (
    <div>
      <div>
        <SearchContainer sx={{ marginTop: "40px" }}>
          <PageHeaderTitle title={pageTitle} />
        </SearchContainer>
      </div>
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Environment</TableCell>
            <TableCell>Public Key</TableCell>
            <TableCell>Secret Key</TableCell>
            <TableCell>Allowed Ips</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.credentials.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.mode}</TableCell>
              <TableCell>{item.key}</TableCell>
              <TableCell>****</TableCell>
              <TableCell>{item.allowedIps}</TableCell>
              <TableCell>
                <BooleanLabel value={item.isActive} type="enable-dis" />
              </TableCell>
              <TableCell>
                <Tooltip title="Generate new Secret">
                  <IconButton
                    onClick={() => {
                      generateCredential(item.id, {
                        onSuccess: (data) => {
                          setSecret(data.secret);
                          refetch();
                        },
                      });
                    }}
                  >
                    <CachedIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={() =>
                    navigate(`./`, {
                      state: { data: item, mode: "EDIT" },
                    })
                  }
                >
                  <EditPencilIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <Dialog open={!!secret} maxWidth={"xs"} onClose={() => setSecret("")}>
        <DialogContent>{secret}</DialogContent>
      </Dialog>
      {state?.mode === "EDIT" && <UpdateCredentialModal />}
    </div>
  );
}

export default CredentialScreen;
