import { useMutation, useQuery } from "react-query";
import { Count, PageFilter, ServerError, VoucherOperationType, VoucherType, useAxios } from "suregifts-component-lib";
import { ActivateVoucherModel, RedeemVoucherModel, VoucherInfoData, VoucherTransactionData, VoucherTransactionFilter } from "../shared/voucher";

export const useGetRecentRedemptions = () => {
  const axios = useAxios();
  return useQuery<VoucherTransactionData[], ServerError>({
    queryKey: ["recent-redemption"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/vouchers/api/recent", { params: { type: 8 } }),
  });
};
export const useSwapVoucher = () => {
  const axios = useAxios();
  const path = "/vouchers/api/swap";
  return useMutation<unknown, ServerError, SwapVoucherModel>((data) => {
    return axios.post(path, data);
  });
};
export const useTransferVoucher = () => {
  const axios = useAxios();
  const path = "/vouchers/api/transfer";
  return useMutation<unknown, ServerError, TransferVoucherModel>((data) => {
    return axios.post(path, data);
  });
};
export const useBlacklistVoucher = () => {
  const axios = useAxios();
  const path = "/vouchers/api/blacklist";
  return useMutation<unknown, ServerError, BlacklistVoucherModel>((data) => {
    return axios.post(path, data);
  });
};
export const useDeactivateVoucher = () => {
  const axios = useAxios();
  const path = "/vouchers/api/deactivate";
  return useMutation<unknown, ServerError, DeactivateVoucherModel>((data) => {
    return axios.post(path, data);
  });
};
export const useReactivateVoucher = () => {
  const axios = useAxios();
  const path = "/vouchers/api/reactivate";
  return useMutation<unknown, ServerError, ReactivateVoucherModel>((data) => {
    return axios.post(path, data);
  });
};

export const useReplaceVocuher = () => {
  const axios = useAxios();
  const path = "/vouchers/api/replace";
  return useMutation<VoucherInfoData, ServerError, ReplaceVoucherModel>((data) => {
    return axios.post(path, data);
  });
};
export const useReverseRedemption = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, ReverseRedemptionModel>((model) => {
    return axios.post("/vouchers/api/reverse-redemption", model);
  });
};

export const useGetRedemptionById = (transactionId: any) => {
  const axios = useAxios();
  return useQuery<RedemptionDto, ServerError>({
    queryKey: ["redemptions-by-Id", { transactionId }],
    queryFn: () => axios.get(`/vouchers/api/redemptions/${transactionId}`, {}),
  });
};

export const useExportTransactions = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, VoucherTransactionFilter>((params) => {
    return axios.get("/vouchers/api/transactions/export", { params });
  });
};

export const useGetTransactions = (params: VoucherTransactionFilter) => {
  const axios = useAxios();
  return useQuery<Count<VoucherTransactionData>, ServerError>({
    queryKey: ["redemptions", params],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/vouchers/api/transactions", {
        params,
      }),
  });
};

export const useGetRecentActivations = () => {
  const axios = useAxios();
  return useQuery<VoucherTransactionData[], ServerError>({
    queryKey: ["recent-activations", {}],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/vouchers/api/recent", { params: { typeId: VoucherOperationType.Activation, size: 7 } }),
  });
};

export const useGetBulkVouchers = (params: BulkVoucherFilter) => {
  const axios = useAxios();
  return useQuery<Count<BulkVoucherData>, ServerError>({
    queryKey: ["bulkvouchers", params],
    keepPreviousData: true,
    refetchInterval: 10000,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/vouchers/api/bulk-vouhers", {
        params,
      }),
  });
};

export const useGenerateInStore = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, BulkVoucherModel>((data) => {
    return axios.post("/vouchers/api/bulk-vouhers/in-store", data);
  });
};

export const useGenerateSales = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, BulkVoucherModel>((data) => {
    return axios.post("/vouchers/api/bulk-vouhers/sales", data);
  });
};
export const useActivateVouchers = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, any>((bulkId) => {
    return axios.post(`/vouchers/api/bulk-vouhers/${bulkId}/activate`);
  });
};
export const useCancelVouchers = (bulkId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, string>((reason) => {
    return axios.post(`/vouchers/api/bulk-vouhers/${bulkId}/cancel`, { reason });
  });
};

export const useExportVouchers = () => {
  var axios = useAxios();
  return useMutation((bulkId: number) => {
    return axios.get(`/vouchers/api/bulk-vouhers/${bulkId}/export`);
  });
};

export const useCheckBalance = () => {
  const axios = useAxios();
  return useMutation<VoucherInfoData, ServerError, CheckBalanceModel>((params) => {
    return axios.get(`/vouchers/api/balance`, {
      params,
    });
  });
};
export interface CheckBalanceModel {
  typeId?: string;
  voucherCode?: string;
  serialNumber?: string;
}
export const useActivateVoucher = () => {
  const axios = useAxios();
  return useMutation<VoucherInfoData, ServerError, ActivateVoucherModel>((model) => {
    return axios.post(`/vouchers/api/activate`, model);
  });
};
export const useRedeemVoucher = () => {
  const axios = useAxios();
  return useMutation<VoucherInfoData, ServerError, RedeemVoucherModel>((model) => {
    return axios.post(`/vouchers/api/redeem`, model);
  });
};

export const useExportTransacitons = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, VoucherTransactionFilter>((params) => {
    return axios.get("/vouchers/api/transactions/export", { params });
  });
};
export const useAssignEmail = () => {
  const axios = useAxios();
  return useMutation<VoucherInfoData, ServerError, AssignEmailModel>((model) => {
    return axios.post(`/vouchers/api/assign-email`, model);
  });
};

export const useGetRestoreIncome = (year: any) => {
  const axios = useAxios();
  return useQuery<VoucherIncomeData[], ServerError>({
    queryKey: ["restore-income", { year }],
    queryFn: () =>
      axios.get(`/vouchers/api/restore-income`, {
        params: { year },
      }),
  });
};

export const useGetWebOrderSummaryReport = () => {
  const axios = useAxios();
  return useQuery<WebOrderSummaryDto[], ServerError>({
    queryKey: ["web-order-summary-report", {}],
    queryFn: () => axios.get(`/orders/api/order-summary-report`, {}),
  });
};

export const useExportRestoreIncome = (year) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, unknown>(() => {
    return axios.get("/vouchers/api/restore-income/export", { params: { year } });
  });
};
export const useExportWebOrderSummaryReport = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, any>((params) => {
    return axios.get("/orders/api/order-summary-export", { params });
  });
};

export interface WebOrderSummaryDto {
  countryId: number;
  month: number;
  year: number;
  shippingFee: number;
  printingFee: number;
  serviceFee: number;
  subTotal: number;
  amountPaid: number;
  id: string;
}
export interface AssignEmailModel {
  voucherCode: string;
  email: string;
}
export interface ReverseRedemptionModel {
  transactionId?: any;
  amountUsed?: any;
}

export interface ReverseTransactionModel {
  transactionId: number;
}
export interface ReplaceVoucherModel {
  voucherCode: string;
  expiryDate: string;
  recipientEmail: string;
  newValue: number;
}

export interface ExtendVoucherModel {
  voucherCode: string;
  expiryDate: string;
  feePercent: number;
}
export interface BlacklistVoucherModel {
  serialNumber: number;
  reason: string;
  includeBatch: boolean;
  voucherCode: string;
}
export interface TransferVoucherModel {
  name: string;
  email: string;
  voucherCode: string;
}
export interface SwapVoucherModel {
  voucherCode: string;
  giftId: string;
  feePercent: string;
}
export interface ReplaceVoucherModel {
  voucherCode: string;
  expiryDate: string;
  recipientEmail: string;
  newValue: number;
}

export interface DeactivateVoucherModel {
  serialNumber: number;
  voucherCode: string;
  reason: string;
}
export interface ReactivateVoucherModel {
  serialNumber: number;
  voucherCode: string;
  reason: string;
}

export interface MerchantNameData {
  id: number;
  name: string;
  isGeneric: boolean;
  isActive: boolean;
}
export interface RedemptionSummaryData {
  merchant: string;
  totalAmount: number;
  totalRedmeptions: number;
  merchantId: number;
}

export interface RedemptionSummaryFilter extends PageFilter {
  fromDate?: Date;
  toDate?: Date;
  typeId: VoucherOperationType;
}

export interface RedemptionDto extends VoucherTransactionData {
  balance: string;
  value: string;
  statusId: number;
  status: string;
  expiryDate: string;
  activationDate: string;
  dateCreated: string;
  unmaskCode: string;
  merchantId: number;
}

export interface BulkVoucherData {
  history: string[];
  id: number;
  typeId: VoucherType;
  type: string;
  totalGenerated: number;
  totalCreated: number;
  merchantName: string;
  isSuregifts: boolean;
  quantity: number;
  value: number;
  dateCreated: string;
  statusId: BulkVoucherStatusType;
  status: string;
  expiryDate?: Date;
  requesterEmail: string;
}
export interface MerchantData {
  id: string;
  name: string;
  denominations: number[];
  currencySymbol: string;
  isSuregifts: boolean;
  minAmount: number;
  maxAmount: number;
}
export interface CancelModel {
  reason: string;
  bulkId: any;
}
export interface BulkVoucherModel {
  merchantId: string;
  voucherValue: number;
  quantity: number;
  expiryDate: string;
  requesterEmail?: string;
  activated: "true" | "false";
  typeId: "Sales" | "Instore";
}

export interface BulkVoucherFilter extends PageFilter {
  status?: BulkVoucherStatusType;
}
export enum BulkVoucherStatusType {
  Processing = 1,
  UnActivated = 2,
  Activated,
  Canceled,
  Failed,
}
export interface VoucherIncomeData {
  month: number;
  year: number;
  amount: number;
}
