import { RouteObject } from "react-router-dom";
import DashboardScreen from "../pages/merchant-backend/dashboard/DashboardScreen";
import PersonnelScreen from "../pages/merchant-backend/personnels/PersonnelScreen";
import RedemptionsScreen from "../pages/merchant-backend/redemptions/RedemptionsScreen";

import MerchantsScreen from "../pages/merchant-backend/merchants/MerchantsScreen";
import AddMerchantModal from "../pages/merchant-backend/merchants/modals/AddMerchantModal";
import EditMerchantModal from "../pages/merchant-backend/merchants/modals/EditMerchantModal";
import AddPersonnelModal from "../pages/merchant-backend/personnels/AddPersonnelModal";
import StoresScreen from "../pages/merchant-backend/stores/StoresScreen";
import AddStoreModal from "../pages/merchant-backend/stores/modals/AddStoreModal";
import EditStoreModal from "../pages/merchant-backend/stores/modals/EditStoreModal";
import AcceptApplicationModal from "../pages/merchant-backend/applications/modals/AcceptApplicationModal";
import RejectApplicationModal from "../pages/merchant-backend/applications/modals/RejectApplicationModal";
import ApplicationScreen from "../pages/merchant-backend/applications/ApplicationScreen";
import DeactivateVoucherModal from "../pages/merchant-backend/voucher-operations/modals/DeactivateVoucherModal";
import ActivateVoucherModal from "../pages/merchant-backend/voucher-operations/modals/ActivateVoucherModal";
import { CheckBalanceButtonView } from "../components/molecules/CheckBalanceButtonView";
import RedeemVoucherModal from "../pages/merchant-backend/voucher-operations/modals/RedeemVoucherModal";
import VoucherOperationScreen from "../pages/merchant-backend/voucher-operations/VoucherOperationScreen";
import CheckBalanceModal from "../pages/merchant-backend/voucher-operations/modals/CheckBalanceModal";
import AssignEmailModal from "../pages/customer-support/dashboard/modals/AssignEmailModal";
import ActivationsScreen from "../pages/merchant-backend/activations/ActivationsScreen";
import SwapVoucherModal from "../pages/merchant-backend/voucher-operations/modals/SwapVoucherModal";
import TransferVoucherModal from "../pages/merchant-backend/voucher-operations/modals/TransferVoucherModal";
import ReactivateVoucherModal from "../pages/merchant-backend/voucher-operations/modals/ReactivateVoucherModal";

import BlacklistVoucherModal from "../pages/merchant-backend/voucher-operations/modals/BlacklistVoucherModal";
import ReplaceVoucherModal from "../pages/merchant-backend/voucher-operations/modals/ReplaceVoucherModal";
import CampaignScreen from "../pages/merchant-backend/campaigns/CampaignScreen";
import ExternalProductScreen from "../pages/merchant-backend/external-products/ExternalProductScreen";
import EsimPackageScreen from "../pages/merchant-backend/esim/EsimPackageScreen";
import CredentialScreen from "../pages/merchant-backend/credentials/CredentialScreen";
import { UpdateCredentialModal } from "../pages/merchant-backend/credentials/UpdateCredentialModal";

export const merchanBackendRoutes: RouteObject[] = [
  { index: true, element: <DashboardScreen /> },
  { path: "dashboard", element: <DashboardScreen /> },
  {
    path: "merchants",
    element: <MerchantsScreen />,
    children: [
      { path: "add", element: <AddMerchantModal /> },
      {
        path: ":merchantId/edit",
        element: <EditMerchantModal />,
      },
    ],
  },
  {
    path: "stores",
    element: <StoresScreen />,
    children: [
      { path: "add", element: <AddStoreModal /> },
      {
        path: ":storeId/edit",
        element: <EditStoreModal />,
      },
    ],
  },
  { path: "merchants/:merchantId/credentials", element: <CredentialScreen />, children: [] },
  { path: "merchants/:merchantId/personnels", element: <PersonnelScreen />, children: [{ path: "add", element: <AddPersonnelModal /> }] },
  {
    path: "applications",
    element: <ApplicationScreen />,
    children: [
      { path: ":applicationId/accept", element: <AcceptApplicationModal /> },
      { path: ":applicationId/reject", element: <RejectApplicationModal /> },
    ],
  },
  {
    path: "voucher-operations",
    element: <VoucherOperationScreen />,
    children: [
      { path: "activate", element: <ActivateVoucherModal /> },
      { path: "transfer/:voucherCode", element: <TransferVoucherModal /> },
      { path: "swap/:voucherCode", element: <SwapVoucherModal /> },
      { path: "reactivate/:voucherCode", element: <ReactivateVoucherModal /> },
      { path: "deactivate/:voucherCode", element: <DeactivateVoucherModal /> },
      { path: "blacklist/:voucherCode", element: <BlacklistVoucherModal /> },
      { path: "replace/:voucherCode", element: <ReplaceVoucherModal /> },
      { path: "redeem", element: <RedeemVoucherModal /> },
      {
        path: "assign-email/:voucherCode",
        element: <AssignEmailModal />,
      },
      { path: "balance", element: <CheckBalanceModal /> },
      {
        path: "assign-email/:voucherCode",
        element: <AssignEmailModal />,
      },
    ],
  },
  { path: "redemptions", element: <RedemptionsScreen /> },
  { path: "activations", element: <ActivationsScreen /> },
  { path: "campaigns", element: <CampaignScreen /> },
  { path: "external-products", element: <ExternalProductScreen /> },
  { path: "esim-packages", element: <EsimPackageScreen /> },
];
