import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FormInput, FormButton, useAuthContext, LoginModel, YupShape, useDocumentTitle } from "suregifts-component-lib";
import { useLogin } from "../../../services/auth.api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const schema = yup
  .object<YupShape<LoginModel>>({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();
export const LoginForm = () => {
  useDocumentTitle("Login");
  const navigate = useNavigate();
  const auth = useAuthContext();
  const { mutate: login, isLoading } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginModel>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/");
    }
  }, [auth.user]);

  const submitForm = (data: LoginModel) => {
    login(data, {
      onSuccess: (data) => {
        auth.saveToken(data.token, data.refreshToken, data);
      },
    });
  };
  return (
    <Box sx={{ p: "2.5rem", position: "relative" }} component={"form"} onSubmit={handleSubmit(submitForm)}>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <FormInput
            label="Username"
            placeholder="Enter User Name"
            {...register("email", { required: { value: true, message: "Email is required" } })}
            helperText={errors.email?.message}
          />
          <FormInput
            label="Password"
            type="password"
            placeholder="Enter Password"
            {...register("password", { required: { value: true, message: "Password is required." } })}
            helperText={errors.password?.message}
          />
        </Stack>
        <FormButton loading={isLoading} disabled={!isValid} fullWidth type="submit" label="Sign in" color="secondary" />
        <Box
          component={Link}
          to={"/forgot-password"}
          sx={{
            color: "inherit",
            textDecoration: "none",
            textAlign: "center",
          }}
        >
          Forgot Password?
        </Box>
      </Stack>
    </Box>
  );
};
