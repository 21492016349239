import { useMutation } from "react-query";
import { ProfileData, ServerError, getCaptchaToken, useAxios } from "suregifts-component-lib";
export const useLogout = () => {
  var axios = useAxios();

  return useMutation<LoginResponse, ServerError, any>((_) => {
    return axios.post("/admin-security/api/logout");
  });
};
export const useLogin = () => {
  var axios = useAxios();

  return useMutation<LoginResponse, ServerError, LoginModel>(async (values) => {
    return axios.post("/admin-security/api/login", values, { headers: { Captcha: await getCaptchaToken() } });
  });
};
export const useChangePassword = () => {
  var axios = useAxios();

  return useMutation<LoginResponse, ServerError, ChangePasswordModel>((values) => {
    return axios.post("/admin-security/api/change-password", values);
  });
};
export const useResetPassword = () => {
  var axios = useAxios();

  return useMutation<LoginResponse, ServerError, ResetPasswordModel>((values) => {
    return axios.post("/admin-security/api/reset-password", values);
  });
};
export const useUpdateProfile = () => {
  var axios = useAxios();

  return useMutation<ProfileData, ServerError, UpdateProfileModel>((values) => {
    return axios.post("/admin-security/api/profile", values);
  });
};

export interface CountryDto {
  name: string;
  dailCode: string;
  isoCode: string;
}

export interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
export interface LoginModel {
  email: string;
  password: string;
}
export interface RefreshTokenModel {
  refreshToken: string;
}
export interface ResetPasswordModel {
  email: string;
}
export interface UpdateProfileModel {
  firstName: string;
  lastName: string;
}

export interface LoginResponse extends ProfileData {
  refreshToken: string;
  token: string;
}
export enum RolesEnum {
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  BUSINESS_DEVELOPMENT = "BUSINESS_DEVELOPMENT",
  STORE_PERSONNEL = "STORE_PERSONNEL",
  FINANCE = "FINANCE",
  SUPER_ADMIN = "SUPER_ADMIN",
  STORE_CONTROLLER = "STORE_CONTROLLER",
  ADMIN = "ADMIN",
  SENIOR_SUPPORT = "SENIOR_SUPPORT",
}
