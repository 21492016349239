import { RouteObject } from "react-router";
import PersonnelScreen from "../pages/merchant-dashboard/personnels/PersonnelScreen";

import { VoucherOperationType } from "suregifts-component-lib";
import RedemptionsScreen from "../pages/merchant-dashboard/redemptions/RedemptionsScreen";
import MerchantWalletHistoryScreen from "../pages/merchant-dashboard/wallet/MerchantWalletHistoryScreen";
import StoreWalletsScreen from "../pages/merchant-dashboard/wallet/StoreWalletsScreen";
import StoreWalletHistoryScreen from "../pages/merchant-dashboard/wallet/StoreWalletHistoryScreen";
import { Navigate, Outlet } from "react-router-dom";
import StoreScreen from "../pages/merchant-dashboard/stores/StoresScreen";
import AddStoreModal from "../pages/merchant-dashboard/stores/modals/AddStoreModal";
import EditStoreModal from "../pages/merchant-dashboard/stores/modals/EditStoreModal";
import AddPersonnelModal from "../pages/merchant-dashboard/personnels/AddPersonnelModal";
import ActivateVoucherModal from "../pages/merchant-dashboard/voucher-operations/modals/ActivateVoucherModal";
import RedeemVoucherModal from "../pages/merchant-dashboard/voucher-operations/modals/RedeemVoucherModal";
import CheckBalanceModal from "../pages/merchant-dashboard/voucher-operations/modals/CheckBalanceModal";
import VoucherOperationScreen from "../pages/merchant-dashboard/voucher-operations/VoucherOperationScreen";
import ActivationsScreen from "../pages/merchant-dashboard/activatations/ActivationsScreen";
import CredentialScreen from "../pages/merchant-dashboard/credentials/CredentialScreen";

export const merchanDashboardRoutes: RouteObject[] = [
  {
    path: "stores",
    element: <StoreScreen />,
    children: [
      { path: "add", element: <AddStoreModal /> },
      { path: ":storeId/edit", element: <EditStoreModal /> },
    ],
  },
  { path: "personnels", element: <PersonnelScreen />, children: [{ path: "add", element: <AddPersonnelModal /> }] },
  { path: "redemptions", element: <RedemptionsScreen /> },
  {
    index: true,
    element: <DefaultRoute />,
  },
  {
    path: "voucher-operations",
    element: <VoucherOperationScreen />,
    children: [
      { path: "activate", element: <ActivateVoucherModal /> },
      { path: "redeem", element: <RedeemVoucherModal /> },
      { path: "balance", element: <CheckBalanceModal /> },
    ],
  },
  {
    path: "activations",
    element: <ActivationsScreen />,
  },
  { path: "credentials", element: <CredentialScreen />, children: [] },
  {
    path: "wallet-transactions",
    element: <MerchantWalletHistoryScreen />,
  },
  {
    path: "store-wallets",
    element: <Outlet />,
    children: [
      { index: true, element: <StoreWalletsScreen /> },
      {
        path: ":storeId/transactions",
        element: <StoreWalletHistoryScreen />,
      },
    ],
  },
];
function DefaultRoute() {
  return (
    <>
      <Navigate to={"voucher-operations"} />;
    </>
  );
}
