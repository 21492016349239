import { useMutation, useQuery } from "react-query";
import { Count, MerchantApprovalType, NameAndId, PageFilter, ServerError, useAxios } from "suregifts-component-lib";
import { StoreFilter, StoreData, CreateEditStoreModel, PersonnelData, AddPersonnelModel } from "../shared/merchants";

export const useGetSwapMerchants = (giftId) => {
  const axios = useAxios();
  return useQuery<SwapMerchantDto[], ServerError>({
    queryKey: ["swap-merchants", giftId],
    initialData: [],
    enabled: !!giftId,
    queryFn: () =>
      axios.get("/merchants/api/swap-merchants", {
        params: { giftId },
      }),
  });
};
export const useGetMerchantNames = () => {
  const axios = useAxios();
  return useQuery<MerchantNameDto[], ServerError>({
    queryKey: ["merchant-names"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/merchants/names", {}),
  });
};
export const useGetStoreNames = (merchantId?: any) => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["store-names", merchantId],
    keepPreviousData: true,
    initialData: [],
    enabled: !!merchantId,
    queryFn: () =>
      axios.get("/merchants/api/stores/names", {
        params: { merchantId },
      }),
  });
};
export const useGetCategories = () => {
  const axios = useAxios();
  return useQuery<ProductCategoryDto[], ServerError>({
    queryKey: ["categories"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/categories", {}),
  });
};
export const useGetPaymentTypes = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["payment-types"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/payment-types", {}),
  });
};
export const useGetStates = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["states"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/states", {}),
  });
};
export const useGetRoles = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["roles"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/merchants/api/roles", {}),
  });
};
//Merchants stories

export const useCreateMerchant = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreateEditMerchantModel>((model) => {
    return axios.post(`/merchants/api/merchants`, model);
  });
};
export const useEditMerchant = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreateEditMerchantModel>((model) => {
    return axios.post(`/merchants/api/merchants/${model.id}`, model);
  });
};
export const useChangeMerchantStatus = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, any>((merchantId: any) => {
    return axios.post(`/merchants/api/merchants/${merchantId}/status`);
  });
};
export const useDeleteMerchant = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, any>((merchantId: any) => {
    return axios.delete(`/merchants/api/merchants/${merchantId}`);
  });
};
export const useToggleShowOnWeb = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, any>((merchantId: any) => {
    return axios.put(`/merchants/api/merchants/${merchantId}/toggle-show-on-web`);
  });
};
export const useGetMerchants = (filter: MerchantFilter) => {
  const axios = useAxios();
  return useQuery<Count<MerchantData>, ServerError>({
    queryKey: ["merchants", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/merchants/api/merchants", {
        params: filter,
      }),
  });
};
export const useGetMerchantById = () => {
  const axios = useAxios();
  return useMutation<MerchantInfoData, ServerError, any>((merchantId) => {
    return axios.get(`/merchants/api/merchants/${merchantId}`);
  });
};
//Store section
export const useGetStores = (filter: StoreFilter) => {
  const axios = useAxios();
  return useQuery<Count<StoreData>, ServerError>({
    queryKey: ["stores", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () => axios.get("/merchants/api/stores", { params: filter }),
  });
};
export const useCreateStore = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreateEditStoreModel>((model) => {
    return axios.post(`/merchants/api/stores`, model);
  });
};
export const useEditStore = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreateEditStoreModel>((model) => {
    return axios.post(`/merchants/api/stores/${model.id}`, model);
  });
};
export const useDeleteStore = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, StoreData>((model) => {
    return axios.delete(`/merchants/api/stores/${model.id}`, {
      params: {
        merchantId: model.merchantId,
      },
    });
  });
};

//Store personel section
export const useGetPersonnels = (merchantId: any) => {
  const axios = useAxios();
  return useQuery<PersonnelData[], ServerError>({
    queryKey: ["personnels", merchantId],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get(`/merchants/api/${merchantId}/personnels`, {}),
  });
};
export const useCreatePersonnel = (merchantId: any) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, AddPersonnelModel>((model) => {
    return axios.post(`/merchants/api/${merchantId}/personnels`, model);
  });
};

export const useDeletePersonnel = (merchantId: any) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, PersonnelData>((data) => {
    return axios.delete(`/merchants/api/${merchantId}/personnels/${data.id}`, {
      params: { merchantId: data.merchantId },
    });
  });
};

///Application section
export const useRejectApplication = (applicationId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, RejectApplicationModel>((model) => {
    return axios.post(`/merchants/api/applications/${applicationId}/reject`, model);
  });
};
export const useAcceptApplication = (applicationId: any) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreateEditMerchantModel>((model) => {
    return axios.post(`/merchants/api/applications/${applicationId}/accept`, model);
  });
};
export const useGetApplications = (filter: ApplicationFilter) => {
  const axios = useAxios();
  return useQuery<Count<ApplicationData>, ServerError>({
    queryKey: ["applications", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/merchants/api/applications", {
        params: filter,
      }),
  });
};

export interface ApplicationFilter extends PageFilter {
  status: MerchantApprovalType;
}
export interface RejectApplicationModel {
  rejectionReason: string;
}
export interface MerchantNameDto {
  id: number;
  name: string;
  giftId: string;
  isGeneric: boolean;
  isActive: boolean;
}
export interface SwapMerchantDto {
  name: string;
  giftId: string;
}
export interface ApplicationData {
  id: number;
  approvalStatusId: MerchantApprovalType;
  approvalStatus: string;
  address: string;
  email: string;
  name: string;
  contactName: string;
  phone: string;
  dateCreated: string;
  reviewDate: string;
  rejectionReason: string;
}
export interface MerchantFilter extends PageFilter {
  active?: boolean;
}
export interface MerchantData {
  isGeneric: boolean;
  id: string;
  name: string;
  email: string;
  phone: string;
  minAmount: number;
  paymentMargin: number;
  isActive: boolean;
  isVisibleOnline: boolean;
  stores: number;
  superMerchantId?: string;
  isSuperMerchant: boolean;
}
export interface MerchantInfoData extends MerchantData {
  address: string;
  dateCreated: string;
  rcNumber: string;
  photos: string[];
  redemptionKey: string;
  description: string;
  featuredPhoto: string;
  industry: string;
  paymentTypeId: number;
  categories: NameAndId[];
  redmeptionDetails: string[];
  isActive: boolean;
  stores: number;
}
export interface CreateEditMerchantModel {
  id: string;
  name: string;
  email: string;
  phone: string;
  photos: string[];
  paymentType: string;
  redemptionKey: string;
  description: string;
  paymentMargin: number;
  categories: number[];
  redmeptionDetails: string[];
  redmeptionDetail: string;
  minAmount: number;
  address: string;
  swapRate: string;
  superMerchantId?: string;
  isSuperMerchant: boolean;
}
//product config

export const useGetProducts = (filter: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<ProductDto>, ServerError>({
    queryKey: ["products", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/merchants/api/products", {
        params: filter,
      }),
  });
};

export const useSwapCategory = () => {
  const axios = useAxios();
  return useMutation<any, ServerError, SwapCategoryModel>((model) => {
    return axios.post(`/merchants/api/products/swap-category`, model);
  });
};
export const useConfigureProduct = () => {
  const axios = useAxios();
  return useMutation<any, ServerError, ProductConfigModel>((model) => {
    return axios.post(`/merchants/api/products/configure`, model);
  });
};
export interface ProductProviderDto {
  id: number;
  name: string;
}
export interface ProductDto {
  vendorType: string;
  productId: string;
  isSwapable: boolean;
  isVisibleOnline: boolean;
  name: string;
  swapRate: number;
  orderDiscountMargin: number;
  paymentMargin: number;
  isVisibleToApi: boolean;
  isActive: boolean;
  imageUrl: string;
  vendorTypeId: number;
  profitMarginOverride: number;
  profitMargin: string;
  categories: ProductCategoryDto[];
}

export interface ProductCategoryDto {
  id: string;
  name: string;
  isExternal: boolean;
}
export interface ProductConfigModel {
  productId: string;
  isSwapable: boolean;
  isVisibleOnline: boolean;
  name: string;
  swapRate: number;
  orderDiscountMargin: number;
  profitMarginOverride: number;
  isVisibleToApi: boolean;
  isActive: boolean;
  imageUrl: string;
  categoryIds: string[];
}

export interface SwapCategoryModel {
  fromCatId: string;
  toCartId: string;
}

export const useGetCredentials = (merchantId) => {
  const axios = useAxios();
  return useQuery<MerchantCredentailDto, ServerError>({
    queryKey: ["credentials"],
    keepPreviousData: true,
    initialData: { credentials: [], name: "", id: 0 },
    queryFn: () => axios.get(`/merchants/api/${merchantId}/credentials`, {}),
  });
};

export const useUpdateCredential = (merchantId) => {
  const axios = useAxios();
  return useMutation<MerchantCredentailDto, ServerError, CredentialModel>((model) => {
    return axios.post(`/merchants/api/${merchantId}/credentials/${model.credentialId}`, model);
  });
};

export const useGenerateCredential = (merchantId) => {
  const axios = useAxios();
  return useMutation<SecretDto, ServerError, any>((credentialId) => {
    return axios.post(`/merchants/api/${merchantId}/credentials/${credentialId}/generate`);
  });
};
export interface MerchantCredentailDto {
  credentials: CredentialDto[];
  name: string;
  id: number;
}
export interface CredentialDto {
  isActive: boolean;
  allowedIps: string;
  key: string;
  mode: string;
  id: number;
  hasSecret: boolean;
}
export interface SecretDto {
  key: string;
  secret: string;
}
export interface CredentialModel {
  credentialId: number;
  allowedIps: string;
  isActive: boolean;
}
