import { Outlet, RouteObject } from "react-router";
import DashboardScreen from "../pages/account/Dashboard/DashboardScreen";
import { RefundsScreen } from "../pages/account/Refunds/RefundsScreen";
import { PaymentsScreen } from "../pages/account/Payments/PaymentsScreen";
import StoresScreen from "../pages/account/wallets/stores/StoresScreen";
import StoreWalletHistoryScreen from "../pages/account/wallets/stores/StoreWalletHistoryScreen";
import MerchantsScreen from "../pages/account/wallets/merchants/MerchantsScreen";
import MerchantWalletHistoryScreen from "../pages/account/wallets/merchants/MerchantWalletHistoryScreen";
import ApprovePaymentModal from "../pages/account/Payments/modals/ApprovePaymentModal";
import RejectPaymentModal from "../pages/account/Payments/modals/RejectPaymentModal";
import ViewPaymentModal from "../pages/account/Payments/modals/ViewPaymentModal";
import ApproveRefundModal from "../pages/account/Refunds/modals/ApproveRefundModal";
import DeclientRefundModal from "../pages/account/Refunds/modals/DeclientRefundModal";
import ViewRefundModal from "../pages/account/Refunds/modals/ViewRefundModal";
import BulkVoucherScreen from "../pages/account/vouchers/BulkVoucherScreen";
import GenerateVoucherModal from "../pages/account/vouchers/modals/GenerateVoucherModal";
import RedemptionsScreen from "../pages/account/redemptions/RedemptionsScreen";
import ActivationsScreen from "../pages/account/activations/ActivationsScreen";
import RedemptionInfoModal from "../pages/account/redemptions/RedemptionInfoModal";
import RestoreIncomeScreen from "../pages/account/restore-income/RestoreIncomeScreen";
import WebOrderSummaryScreen from "../pages/account/restore-income copy/WebOrderSummaryScreen";

export const accountRoutes: RouteObject[] = [
  { index: true, element: <DashboardScreen /> },
  { path: "dashboard", element: <DashboardScreen /> },
  {
    path: "payments",
    element: <PaymentsScreen />,
    children: [
      { path: ":paymentId/approve", element: <ApprovePaymentModal /> },
      {
        path: ":paymentId/reject",
        element: <RejectPaymentModal />,
      },
      {
        path: ":paymentId/view",
        element: <ViewPaymentModal />,
      },
    ],
  },
  { path: "activations", element: <ActivationsScreen /> },
  { path: "redemptions", element: <RedemptionsScreen /> },
  { path: "restore-income", element: <RestoreIncomeScreen /> },
  { path: "order-summary-report", element: <WebOrderSummaryScreen /> },
  {
    path: "refunds",
    element: <RefundsScreen />,
    children: [
      { path: ":refundId/approve", element: <ApproveRefundModal /> },
      {
        path: ":refundId/decline",
        element: <DeclientRefundModal />,
      },
      {
        path: ":refundId/view",
        element: <ViewRefundModal />,
      },
    ],
  },
  {
    path: "vouchers",
    element: <BulkVoucherScreen />,
    children: [
      {
        path: "generate",
        element: <GenerateVoucherModal />,
      },
    ],
  },
  {
    path: "merchants",
    element: <Outlet />,
    children: [
      { index: true, element: <MerchantsScreen /> },
      {
        path: ":merchantId/transactions",
        element: <MerchantWalletHistoryScreen />,
      },
    ],
  },
  {
    path: "stores",
    element: <Outlet />,
    children: [
      { index: true, element: <StoresScreen /> },
      {
        path: ":storeId/transactions",
        element: <StoreWalletHistoryScreen />,
      },
    ],
  },
];
